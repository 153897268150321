import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
import 'lib-flexible'
// import './utils/rem'
import 'swiper/swiper-bundle.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'video.js/dist/video-js.css';


const app = createApp(App)
app.use(VideoPlayer)
app.use(VueAwesomeSwiper)
app.use(router)

app.mount('#app')
