<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
#app,html,body {
  margin: 0;
}
@include pc1024() {
  #app,html,body {
    min-width: 1190Px;
  }
}
@include h5() {
  min-width: 100vh;
}
</style>
