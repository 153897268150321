<template>
    <div class="home-page">
      <div class="banner">
        <!-- class="banner-pc" -->
        <img  src="@/assets/about-banner.png" alt="">
        <!-- <img class="banner-h5" src="@/assets/about-banner-h5.png" alt=""> -->
        <div class="banner-tips">
            <div>关于我们</div>
            <div>About us</div>
        </div>
      </div>
      <div class="advantages">
        <div class="common-title">
          <span>公司简介</span>
          <span class="common-tips">company introduction</span>
        </div>
        <div class="advantages-li">
          <div class="advantages-left advantages-pc">
            <div class="left-top"></div>
            <img src="@/assets/introduction.png" alt="">
            <div class="right-bottom"></div>
          </div>
          <div class="advantages-left advantages-h5">
            <img src="@/assets/introduction-h5.png" alt="">
          </div>
          <div class="advantages-right">
            <div>博润前程 - 连接两岸，打造移居新未来</div>
            <div>博润前程是一家以香港移居为核心目标的科技公司，注册在北京和香港。</div>
            <div>我们的愿景是有效链接两岸，帮助更多希望移居香港的朋友高效地取得香港身份，并让已经取得香港身份的新港人更稳妥地获得永久居留权，享受香港的教育、医疗、购房、财富管理等各种福利。</div>
            <div>作为专注于香港移居垂直项目的企业，我们的团队由多位具有丰富行业经验的专业人士组成，团队创始成员有来自BAT的互联网高管，有来自于香港金融领域的财务管理专家，团队成员均拥有香港身份，拥有严谨的工作态度和高效的执行力。</div>
            <div>我们与香港政府机构、家族办公室、律师事务所、香港高校、房产管理公司等合作，为客户提供一站式的香港移居服务，包括香港身份咨询、香港身份及各类签证申请、香港身份续签咨询、永居办理咨询、香港就医、香港购房置业、财富管理等服务。</div>
            <div>博润前程秉承“诚信、专业、高效、创新”的核心价值观，为客户提供最优质的服务。我们的使命是成为最受信赖的香港移居服务品牌，让更多的客户实现移居梦想，开启崭新的人生旅程。</div>
            <div>博润前程，与你共创美好前程！</div>
          </div>
        </div>
      </div>
  
      <div class="processing processing-con">
        <div class="common-title">
          <span>企业文化</span>
          <span class="common-tips">enterprise culture</span>
        </div>
        <div class="processing-li">
            <div class="processing-row" v-for="(item,index) in processing" :key="index">
                <img class="processing-icon" :src="item.icon" alt="">
                <div class="processing-intr">
                    <div class="processing-title">{{ item.title }}</div>
                    <div class="processing-tips">{{ item.tips }}</div>
                </div>
            </div>
        </div>
      </div>
  
      <div class="processing" v-for="(item, itemIndex) in servicesList" :key="itemIndex">
        <div class="common-title">
          <span>{{ item.title }}</span>
          <span class="common-tips">{{ item.titleTips }}</span>
        </div>
        <div class="services services-pc">
          <div class="about-services-li" v-for="(i,iIndex) in item.list" :key="iIndex">
            <img :src="i.img" alt="">
            <div class="about-services-content">
              <div class="about-services-title">{{ i.title }}</div>
              <div class="about-services-tips">{{ i.tips }}</div>
              <div class="about-services-bar"></div>
              <div class="about-services-case">{{ i.content }}</div>
            </div>
          </div>
        </div>
        <div class="services-h5">
          <Swiper class="swiper-h5" :list="item.list"/>
        </div>
      </div>

      <div class="Contact">
        <div class="common-title">
          <span>联系我们</span>
          <span class="common-tips">Contact us</span>
        </div>
        <div class="Contact-list">
            <div class="Contact-li" v-for="itemI in caseList" :key="itemI.title">
                <div class="Contact-title">{{ itemI.title }}</div>
                <div class="Contact-content" v-if="itemI.icon">
                    <img class="Contact-img" :src="itemI.icon" alt="">
                    <div class="Contact-tips">{{ itemI.tips }}</div>
                </div>
                <div v-else class="Contact-way">
                    <div class="Contact-phone">
                        <img src="@/assets/phone.png" alt="">
                        <span class="Contact-num">+8618911057086</span>
                        <span></span>
                    </div>
                    <div class="Contact-phone Contact-whatsApp">
                        <img src="@/assets/WhatsApp.png" alt="">
                        <span class="Contact-num">+85267559270</span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
      </div>  
    </div>
  </template>
  
  <script>
  import Swiper from "./Swiper.vue"
  export default {
    data() {
      return {
        processing: [
          {
            icon: require('@/assets/culture_1.png'),
            title: '企业使命',
            tips: '提供可靠的服务帮助客户高效取得香港身份，获取永居，享受香港福利',
          },
          {
            icon: require('@/assets/culture_2.png'),
            title: '企业愿景',
            tips: '有效链接两岸，打造移居新未来，一站式解决移居问题',
          },
          {
            icon: require('@/assets/culture_3.png'),
            title: '企业价值观',
            tips: '可靠、专业、高效',
          }
        ],
        servicesList: [
          {
            title: '项目案例',
            titleTips: 'Project Cases',
            list: [
              {
                img: require('@/assets/case_1.png'),
                title: 'Z女士-优才申请',
                tips: 'Excellent project',
                content:'Z女士是北京的一名互联网产品经理，虽然她的优才评分一般，但博润前程根据她的行业特性和工作内容，制定了适合她的材料申请清单，并对她提供的材料进行了详细的细节打磨，帮助她高效地完成了香港优才计划的申请。通过我们的专业服务和高效执行力，Z女士成功通过了人才清单获得了香港优才计划的批准。现在，她已经成功移居香港，并在香港创建了自己的公司。'
              },
              {
                img: require('@/assets/case_2.png'),
                title: 'L先生-高才申请',
                tips: 'Excellent project',
                content: 'L先生在线上拥有自己的多家公司，希望通过高才A类申请香港身份。但由于公司股权结构较为复杂，L先生过往工作经历又比较杂乱，因此博润前程为他提供了详细的个案分析，并制定了申请计划，逐项与L先生一起进行材料打磨。最终，只用一个星期，L先生的高才A类申请获得批准，他已成功拿到香港身份。'
              },{
                img: require('@/assets/case_3.png'),
                title: 'Y先生-专才申请',
                tips: 'Excellent project',
                content: 'Y先生因为学历一般，过往工作经历都在小公司工作，因此申请优才机会不大。在与Y先生多次商议后，我们最终选择通过香港专才计划为他取得香港身份。博润前程为Y先生推荐了香港上市公司，并提供了专才材料清单制定，并对公司侧及个人侧材料进行补充、修订、润色。最终，Y先生成功入职，并取得了香港身份。'
              }
            ]
          },
        ],
        caseList: [
          {
            title: '咨询电话'
          },
          {
            title: '公众号',
            icon: require('@/assets/gongzhonghao.png'),
            tips: '新港通'
          },
          {
            title: '视频号',
            icon: require('@/assets/shipinghao.png'),
            tips: '博润前程'
          },
          {
            title: '抖音',
            icon: require('@/assets/douyin.png'),
            tips: '博润前程'
          },
        ],
      }
    },
    components: {Swiper},
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      },
    },
  }
  
  </script>
  
  <style scoped lang="scss">
  .banner {
    position: relative;
    padding-top: 63Px;
    width: 100%;
  }
  .banner-pc {
    display: block;
  }
  .banner-h5 {
    display: none;
  }
  .banner img {
    width: 100%;
  }
  .banner-tips {
    position: absolute;
    z-index: 10;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: 32Px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 42Px;
  }
  .banner-tips div:last-child {
    font-size: 14Px;
    font-family: Helvetica-Light, Helvetica;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 17Px;
    padding-top: 12Px;
  }
  .advantages {
    margin-top: 76Px;
  }
  .common-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32Px;
    color: #333333;
    line-height: 42Px;
  }
  .common-tips {
    font-size: 14Px;
    font-weight: 300;
    color: #A6A6A6;
    line-height: 17Px;
    margin-top: 12Px;
  }
  .advantages-pc {
    display: block;
  }
  .advantages-h5 {
    display: none;
  }
  .advantages-li {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 52Px;
  }
  .advantages-left {
    height: 476Px;
    position: relative;
    margin-right: 50Px;
  }
  .advantages-right {
    width: 533Px;
    font-size: 14Px;
    color: #666;
    line-height: 32Px;
    div:first-child {
      font-size: 20Px;
      line-height: 36Px;
      text-indent: 1.4em;
    }
}
.advantages-right div {
    text-indent: 2em;
}
.left-top {
    position: absolute;
    top: -26Px;
    left: -26Px;
    z-index: -1;
    width: 174Px;
    height: 174Px;
    background: #B09850;
}
.right-bottom {
    position: absolute;
    bottom: 0;
    right: -16Px;
    z-index: -1;
    width: 83Px;
    height: 89Px;
    background: #757DC6;
}
  .processing {
    margin-top: 88Px;
  }
  .processing-con {
    background: #F7FAFD;
    padding: 72Px 0 92Px;
  }
  .processing-li {
    margin-top: 48Px;
    display: flex;
    justify-content: center;
  }
  .processing-right {
    width: 572Px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 24Px;
  }
  .processing-right .processing-content {
    margin-bottom: 24Px;
    height: 274Px;
  }
  .processing-right .processing-content:nth-child(1) {
    margin-right: 24Px;
  }
  .processing-row {
    display: flex;
  }
  .processing-icon {
    width: 78Px;
    height: 78Px;
  }
  .processing-content {
    position: relative;
  }
  .processing-one {
    width: 574Px;
  }
  .processing-intr {
    bottom: 19Px;
    padding: 0 24Px;
  }
  .processing-title {
    font-size: 24Px;
    color: #333333;
    line-height: 31Px;
  }
  .processing-tips {
    width: 190Px;
    font-size: 12Px;
    color: #777777;
    line-height: 16Px;
    padding-top: 10Px;
  }
  .processing-list {
    font-size: 14Px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 19Px;
    margin: 12Px 0 0;
  }
  .services {
    margin-top: 39Px;
    display: flex;
    justify-content: center;
  }
  .services-pc {
    display: flex;
  }
  .services-h5 {
    display: none;
  }
  .swiper-h5 {
    position: relative;
    padding: 0 80Px;
    width: calc(100% - 160Px);
    height: unset;
    display: none;
  }
  .about-services-li {
    margin: 0 12Px;
    position: relative;
  }
  .about-services-content {
    position: absolute;
    top: 29Px;
    left: 22Px;
    right: 22Px;
  }
  .about-services-title{
    font-size: 16Px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 21Px;
  }
  .about-services-tips {
    padding: 5Px 0 8Px;
    font-size: 12Px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 16Px;
  }
  .about-services-bar {
    width: 86Px;
    height: 1Px;
    margin-bottom: 10Px;
    background: #fff;
  }
  .about-services-case {
    font-size: 14Px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 26Px;
  }
  .Contact {
    margin-top: 88Px;
    padding: 36Px 0 105Px;
    background: #F7FAFD;
  }
  .Contact-list {
    display: flex;
    justify-content: center;
    margin-top: 54Px;
  }
  .Contact-title {
    font-size: 18Px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 24Px;
  }
  .Contact-content {
    margin-top: 13Px;
    margin-right: 73Px;
  }
  .Contact-tips {
    font-size: 16Px;
    font-family: MicrosoftYaHei;
    color: #666666;
    line-height: 21Px;
    margin-top: 19Px;
    text-align: center;
  }
  .Contact-way {
    padding-right: 80Px;
  }
  .Contact-phone {
    margin: 40Px 0 20Px;
    display: flex;
    align-items: center;
    img {
      width: 24Px;
      height: 24Px;
      padding-right: 10Px ;
    }
  }
  .Contact-num {
    font-size: 20Px;
    font-family: Helvetica-Light, Helvetica;
    font-weight: 300;
    color: #AA934D;
    line-height: 24Px;
  }
  .Contact-phone span:last-child {
    font-size: 20Px;
    font-family: MicrosoftYaHei;
    color: #666666;
    line-height: 26Px;
  }
  .Contact-whatsApp {
    margin: 0;
  }
@include h5() {
  .banner {
    padding-top: 88px;
    img {
      position: relative;
      height: 280px;
      overflow: hidden;
    }
  }
  .banner-h5 {
    display: block;
  }
  .banner-pc {
    display: none;
  }
  .advantages {
    margin-top: 60px;
  }
  .advantages-li {
    margin-top: 58px;
  }
  .common-title {
    font-size: 32px;
    line-height: 42px;
  }
  .common-tips {
    margin-top: 8px;
    line-height: 22px;
  }
  .advantages-li,.processing-li,.processing-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .processing-title {
    text-align: center;
  }
  .processing-tips {
    font-size: 24px;
    line-height: 36px;
    width: unset;
  }
  .advantages-pc {
    display: none;
  }
  .advantages-h5 {
    display: block;
    padding: 0 24px;
    margin: 0;
    img {
      width: 703px;
    }
  }
  .advantages-left {
    height: unset;
  }
  .advantages-right {
    width: calc(100% - 48px);
    padding: 30px 24px 60px;
    font-size: 24px;
    line-height: 36px;
    div:first-child {
      font-size: 30px;
      text-indent: 1.6em;
    }
  }
  .processing {
    margin-top: 0;
  }
  .processing{
    padding-top: 60px;
  }
  .processing-icon {
    width: 78px;
    height: 78px;
    padding-bottom: 24px;
  }
  .processing-title {
    padding-bottom: 24px;
  }
  .processing-tips {
    padding-bottom: 60px;
  }
  .processing-con {
    padding: 72px 0 0px;
  }
  .processing-li {
    margin-top: 37px;
  }
  .processing-tips {
    padding-top: 0px;
  }
  .processing-title {
    font-size: 28px;
    line-height: 37px;
  }
  .processing-intr {
    padding: 0 24px; 
    text-align: center;
  }
  .services-pc {
    display: none;
  }
  .services-h5 {
    display: block;
  }
  .swiper-h5 {
    display: block;
    width: calc(100% - 224px);
    padding: 0 112px 0 112px;
  }
  .Contact {
    margin-top: 60px;
    padding: 36px 0 60px;
  }
  .Contact-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-top: 37px;
    .Contact-li {
      text-align: center;
    }
    .Contact-li:first-child {
      width: 100%;
      text-align: left;
    }
    .Contact-li:nth-child(2) {
      width: 199px;
    }
    .Contact-li:nth-child(3) {
      .Contact-title{
        padding-left: 14px;
      }
      width: 218px;
      .Contact-tips {
        width: 258px;
        margin-left: -18px;
      }
    }
    .Contact-li:last-child {
      .Contact-title{
        padding-left: 20px;
      }
      width: 268px;
      .Contact-tips {
        width: 288px;
      }
    }
    .Contact-tips {
      font-size: 20px;
      transform: scale(0.8);
    }
  }
  .Contact-way {
    display: flex;
    padding: 0;
  }
  .Contact-phone {
    display: flex;
    font-size: 24px;
    line-height: 29px;
    margin: 35px 0 92px 0;
    img {
      width: 30px;
      height: 30px;
      padding-right: 10px ;
    }
    .Contact-num {
      font-size: 24px;
      line-height: 29px;
    }
    span:last-child {
      font-size: 24px;
      line-height: 31px;
    }
  }
  .Contact-phone:first-child {
    padding-right: 20px;
  }
  .Contact-content {
    margin-top: 13px;
    margin-right: 0;
    .Contact-img {
      width: 195px;
      height: 194px;
    }
  }
  .Contact-title {
    text-align: left;
    font-size: 24px;
    line-height: 31px;
  }
  .Contact-tips {
    font-size: 20px;
    line-height: 26px;
    margin-top: 15px;
  }
}
  </style>