<template>
    <div>
        <div v-for="item in itemList" :key="item.name">
            {{ item.name }}
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                itemList: [
                {
                    name: '首页',
                    path: '/home'
                },
                {
                    name: '关于我们',
                    path: '/about'
                }
            ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .tabList {
        position: absolute;
        top: 88px;
        width: 100%;
        height: 100%;
    }
</style>