<template>
    <div :class="['top',{'about-top':$route.path == '/about'}]" :style="{background: `rgba(255,255,255, ${getScroll()})`}">
        <img :class="['logo-bg',{'footer-logo' :getScroll() > 0.5  || $route.path == '/about'}]" :src="getScroll() > 0.5  || $route.path == '/about' ? logoTwo : logoOne"/>
        <div class="tab tab-pc">
            <div :class="['tab-li',{'scroll-tab': getScroll() > 0.5 || $route.path == '/about'}]" v-for="item in tabList" :key="item.id" @click="changeTab(item)">
                <a :href="`#${item.id}`">
                    <div class="tab-name" >{{ item.title }}</div>
                    <div v-if="actived == item.id" class="bar"></div>
                </a>
            </div>
        </div>
        <div class="tab tab-h5" v-if="!tabNavStatus">
            <img class="more-icon" @click="changeH5Tab" :src="getScroll() > 0.5  || $route.path == '/about' ? h5LogoTwo : h5LogoOne"/>
        </div>
        <div class="tab-h5 h5-nav-list" v-if="tabNavStatus">
            <div class="nav-list-top">
                <img class="logo-bg footer-logo" :src="logoTwo"/>
                <img class="more-icon" @click="changeH5Tab" :src="h5LogoTwo"/>
            </div>
            <div class="tab-list">
                <div class="scroll-tab" v-for="item in tabList" :key="item.h5Id" @click="changeTab(item)">
                    <a :href="`#${item.h5Id}`">
                        <div class="tab-name">{{ item.title }}</div>
                        <div v-if="actived == item.h5Id" class="bar"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TabH5 from "./TabList.vue"
export default {
    props: ['scroll','scrollActived'],
    data() {
        return {
            tabList: [
                {id: 1, title: '首页', path: '/home', h5Id: '1'},
                {id: 2, title: '香港身份', path: '/home', id: 'identity', h5Id: 'identity'},
                {id: 3, title: '教育升学', path: '/home', id: 'education', h5Id: 'educationH5'},
                {id: 4, title: '医疗服务', path: '/home', id: 'medical', h5Id: 'medicalH5'},
                {id: 5, title: '投资理财', path: '/home', id: 'invest', h5Id: 'investH5'},
                {id: 6, title: '企业营商', path: '/home', id: 'enterprise', h5Id: 'enterpriseH5'},
                {id: 7, title: '关于我们', path: '/about', h5Id: '7'}
            ],
            logoOne: require('@/assets/logo_bg.png'),
            logoTwo: require('@/assets/footer-logo.png'),
            h5LogoOne: require('@/assets/more-h5.svg'),
            h5LogoTwo: require('@/assets/moer-actived.svg'),
            tabNavStatus: false,
            actived: 1
        }
    },
    watch: {
        $route: {
            handler() {
                let hash = this.$route.hash
                for(let i = 0; i < this.tabList.length; i++) {
                    if(hash == `#${this.tabList[i].id}` || hash == `#${this.tabList[i].h5Id}`) {
                        hash == `#${this.tabList[i].id}` ? this.actived = this.tabList[i].id : this.actived = this.tabList[i].h5Id
                    }
                }
            },
            deep: true,
            immediate: true
        },
        scrollActived: {
            handler() {
                if(this.scrollActived == '') return
                this.scrollChangeTab(this.scrollActived)
            },
            deep: true,
            immediate: true
        },
    },
    components: {TabH5},
    methods: {
        changeTab(item) {
            this.tabNavStatus = false
            this.$router.replace(item.path)
            window.scrollTo({top:0})

        },
        getScroll() {
            let height = Number(this.scroll)

            let opt = 1 - height / 60
            if(height > 60) {
                return 1
            }
            if (opt < 0.1) {
                return 1
            }
            return 1 - opt
        },
        changeH5Tab() {
            this.tabNavStatus = !this.tabNavStatus 
        },
        scrollChangeTab(hash) {
            for(let i = 0; i < this.tabList.length; i++) {
                if(hash == this.tabList[i].id || hash == this.tabList[i].h5Id) {
                    hash == this.tabList[i].id ? this.actived = this.tabList[i].id : this.actived = this.tabList[i].h5Id
                }
            }
            console.log(this.actived)
        }
    }
}
</script>

<style scoped lang="scss">
.top {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 62Px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255,255,255,0);
    border-bottom: 1Px solid rgba(255,255,255,0.18);
}
.about-top {
    background: rgba(255,255,255,1);
    .icon-li-h5 div {
        background: #AA934D!important;
    }
}
.logo-bg {
    width: 120Px;
    height: 40Px;
    padding-left: 114Px;
}
.footer-logo {
    width: 240Px!important;
    height: unset!important;
    margin-left: -60Px!important;
}
.tab {
    display: flex;
    align-items: center;
    padding: 0 114Px;
}
.bar {
    height: 1Px;
    background: #fff;
}
.tab-li {
    font-size: 14Px;
    font-weight: bold;
    color: #FFFFFF;
    padding-left: 40Px;
    cursor: pointer;
    a {
        text-decoration: unset;
        color: #fff;
    }
    a:hover, a:visited, a:link, a:active {
        color: #fff;
    }
}
.tab-li .tab-name {
    padding: 22Px 0 21Px;
}
.scroll-tab {
    a {
        color: #AA934D!important
    }
    a:hover, a:visited, a:link, a:active {
        color: #AA934D!important
    }
}
.scroll-tab .bar {
    height: 2Px;
    background: #AA934D;
}
.tab-pc {
    display: flex;
}
.tab-h5 {
    display: none;
}
@include pc1024() {
  .top {
    min-width: 1190Px;
    width: 1190Px;
  }
}
@include h5() {
    .top {
        height: 88px;
        border: none;
        .logo-bg {
            width: 181px;
            height: 59px;
        }
        .footer-logo {
            width: 380px!important;
            height: unset!important;
            margin-left: -100px!important;
        }
    }
    .tab-pc {
        display: none;
    }
    .tab-h5 {
        display: block;
        .more-icon {
            width: 120px;
            height: 120px;
            margin: 16px -30px 0 0;
        }
    }
    .tab-icon {
        margin-top: 16px;
    }
    .icon-li {
        display: flex;
        div:first-child {
            width: 4px;
            height: 4px;
            background: #FFFFFF;
            border-radius: 50%;
            margin: 0 4px 12px 0;
        }
        div:last-child {
            width: 36px;
            height: 3px;
            background: #FFFFFF;
        }
    }
    .icon-li-h5 div {
        background: #AA934D!important;
    }
    .logo-bg {
        padding-left: 24px;
    }
    .tab {
        padding: 0 24px;
    }
    .tab-name {
        font-size: 30px;
        line-height: 40px;
        padding: 24px 0 23px!important;
    }
    .tab-li:last-child {
        padding-left: 40px;
    }
    .h5-nav-list {
        position: fixed;
        top: 0;
        background: #fff;
        width: 100%;
        height:100%;
        overflow: hidden;
        text-align: center;
        .nav-list-top{
            display: flex;
            justify-content: space-between;
            margin-top: -20px;
            .footer-logo {
                margin-top: -126px;
            }
            .more-icon {
                margin: 8px -6px 0 0;
            }
        }
        .tab-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .scroll-tab {
            width: 150px;
            a {
                color: #AA934D!important;
                text-decoration: unset;
            }
            a:hover, a:visited, a:link, a:active {
                color: #AA934D!important
            }
        }
    }
}
</style>
