<template>
    <div class="warp" @scroll="handleScroll">
        <TabNav :scroll="scroll" :scrollActived="scrollActived"/>
        <keep-alive>
            <HomePage :scroll="scroll" @scrollChangeTab="scrollChangeTab"/>
        </keep-alive>
        <Footer />
    </div>
</template>

<script>
import TabNav from "../components/TabNav.vue"
import HomePage from '../components/Home.vue'
import Footer from "../components/Footer.vue"

export default {
    components: {
        TabNav,
        HomePage,
        Footer
    },
    data(){
        return{
            scroll: 0,
            scrollActived: ''
        }
    },
    mounted(){
        window.addEventListener('scroll',this.handleScroll,true)
    },
    methods:{
        handleScroll(){
            this.scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        },
        scrollChangeTab(id) {
            this.scrollActived = id
        }
    },
    //销毁,否则跳到别的路由还是会出现
    unmounted(){
        window.removeEventListener('scroll',this.handleScroll)
    }
}
</script>

<style>
.warp {
    height: 100%;
    overflow: auto;
}
</style>
