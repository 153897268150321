<template>
    <div>
        <TabNav />
        <AboutPage />
        <Footer />
    </div>
</template>

<script>
import TabNav from "../components/TabNav.vue"
import AboutPage from '../components/About.vue'
import Footer from "../components/Footer.vue"

export default {
    components: {
        TabNav,
        AboutPage,
        Footer
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    margin-top: 0;
}
@include h5() {
    .footer {
        margin-top: 0;
    }
}
</style>
