import Home from '@/views/index'
import About from '@/views/about'

//配置文件

import { createRouter, createWebHistory } from 'vue-router'
const routerHistory = createWebHistory()
const routes=[
  {
    path:'/',
    redirect:"/home",
  },
  {
    path:'/home',
    name:'home',
    component:Home
  },
  {
    path:'/about',
    name:'about',
    component:About
  },
]

const router = createRouter({
  history: routerHistory,
  routes
})
export default router