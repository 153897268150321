<template>
  <div class="home-page" @scroll="handleScroll">
    <div class="banner">
      <!-- <video class="banner-img" src="@/assets/香港视频banner.mp4" autoplay webkit-playsinline="true" playsinline="true" preload="auto" muted  poster="@/assets/video-bg.png"></video> -->
      <video id="bannerVideo" class="banner-img"
        muted 
        autoplay 
        preload 
        loop
        x5-playsinline
        playsinline
        webkit-playsinline
        poster="@/assets/video-bg.png"
      >
          <source src="@/assets/香港视频banner.mp4" type="video/mp4" >
      </video>
      <div class="banner-mask">
        <span>移居香港</span>
        <span>一站式解决方案提供商</span>
      </div>
    </div>
    <div class="advantages">
      <div class="common-title">
        <span>香港身份优势</span>
        <span class="common-tips">Hong Kong Identity Advantages</span>
      </div>
      <div class="advantages-li">
        <div class="li" v-for="(item,index) in advantages" :key="index">
          <img :src="item.icon" alt="">
          <div class="li-title">{{ item.title }}</div>
          <span class="li-tips">{{ item.tips }}</span>
          <div class="list-li">
            <div v-for="(el,elIndex) in item.list" :key="elIndex">{{ el }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="identity" class="scroll-item" style="height:1px"></div>
    <div  class="processing">
      <div class="common-title">
        <span>香港身份办理</span>
        <span class="common-tips">Hong Kong identity processing</span>
      </div>
      <div class="processing-li">
        <div class="processing-content processing-one">
          <img src="@/assets/processing_1.png" alt="">
          <div class="processing-img-mask"></div>
          <div class="processing-intr">
            <div class="processing-title">优秀人才入境计划</div>
            <div class="processing-tips">3+3+2全家移居，续签灵活</div>
            <div class="processing-list">香港优才是港府于2006年推出的一项移居计划，旨在吸引高技术和行业优秀人才定居香港。申请人综合评分80分以上即可递交移民申请（总分245），获批后可带家人移居香港。</div>
          </div>
        </div>
        <div class="processing-right">
          <div class="processing-row" v-for="(item,index) in processing" :key="index">
            <div class="processing-content" v-for="(el,elIndex) in item" :key="elIndex">
              <img :src="el.icon" alt="">
              <div class="processing-img-mask"></div>
              <div class="processing-intr">
                <div class="processing-title">{{ el.title }}</div>
                <div class="processing-tips">{{ el.tips }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services-pc" v-for="(item, itemIndex) in servicesList" :key="itemIndex">
      <div :id="item.point" class="scroll-item" style="height:1px"></div>
      <div class="processing services-pc" ></div>
      <div class="common-title">
        <span>{{ item.title }}</span>
        <span class="common-tips">{{ item.titleTips }}</span>
      </div>
      <div class="services">
        <div class="services-li" v-for="(i,iIndex) in item.list" :key="iIndex">
          <img :src="i.img" alt="">
          <div class="services-img-mask"></div>
          <div class="services-content">
            <div class="services-title">{{ i.title }}</div>
            <span class="services-tips">{{ i.tips }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="services-h5" v-for="(item, itemIndex) in servicesList" :key="itemIndex">
      <div :id="`${item.point}H5`" class="scroll-item" style="height:1px"></div>
      <div class="common-title processing">
        <span>{{ item.title }}</span>
        <span class="common-tips">{{ item.titleTips }}</span>
      </div>
      <Swiper class="swiper-h5" :list="item.list" type="home" :itemIndex="itemIndex"/>

    </div>

  </div>
</template>

<script>     
import Swiper from "./Swiper.vue"
export default {
  components:{
    Swiper,
  },
  data() {
    return {
      advantages: [
        {
          icon: require('@/assets/advantages_1.png'),
          title: '教育规划',
          tips: '低200分左右上名校',
          list: [
            '1、国际学校：香港DSE成绩被300+大学认可',
            '2、内地名校：可申请内地130+所优质大学',
            '3、香港名校：8大名校优先录取',
            '4、华侨联考：低200多分上内地985、211',
          ]
        },
        {
          icon: require('@/assets/advantages_2.png'),
          title: '医疗保障',
          tips: '获得国际顶尖医疗保障',
          list: [
            '1、医疗技术好：肺癌、肝癌等肿瘤治疗治愈率高，全球领先',
            '2、治疗费用低：公立医院门诊、住院费几乎全免，子女疫苗免费',
            '3、医疗体验好：私立医院众多，商保覆盖全面，就医体验极好',
          ]
        },
        {
          icon: require('@/assets/advantages_3.png'),
          title: '投资理财',
          tips: '实现全球化的资产配置',
          list: [
            '1、全球资产：储备港元、美元对抗货币贬值风险',
            '2、港险配置：产品丰富、费率低、保障强',
            '3、房产投资：香港房产得房率高、租金高、贷款利率低、适合投资',
          ]
        },
        {
          icon: require('@/assets/advantages_4.png'),
          title: '合理避税',
          tips: '香港税制简单，税收低',
          list: [
            '1、企业低税：企业税制简单，企业税低于16.5%',
            '2、个人低税：个人税务减免，个人税率低于17%',
            '3、内地减税：在内地可享受海外身份减税',
            '4、永居免税：永久购房减免30%印花税',
          ]
        }
      ],
      processing: [
        [{
          icon: require('@/assets/processing_2.png'),
          title: '高端人才通行计划',
          tips: '2+3+3审批周期快，条件宽松',
        },
        {
          icon: require('@/assets/processing_3.png'),
          title: '香港专才入境计划',
          tips: '无需投资，大专起步，快速获批',
        },],
        [{
          icon: require('@/assets/processing_4.png'),
          title: '香港投资移民计划',
          tips: '条件简单，可一次性拿永居',
        },
        {
          icon: require('@/assets/processing_5.png'),
          title: '香港留学入境计划',
          tips: '学历提升与身份获批同步完成',
        },]
      ],
      servicesList: [
        {
          point: 'education',
          title: '香港教育服务',
          titleTips: 'Hong Kong Education Services',
          list: [
            {
              img: require('@/assets/services_1.png'),
              title: '香港暑期夏令营',
              tips: '帮助内地新港孩子，融入全英文环境，积攒永居时长'
            },
            {
              img: require('@/assets/services_2.png'),
              title: '香港暑期小初高入学',
              tips: '帮助内地新港孩子，择校录取，提供全程辅助支持'
            },{
              img: require('@/assets/services_3.png'),
              title: '香港大学升学',
              tips: 'DSE考试、华侨生联考、副学士申请、本硕申请，助力入读心仪高校'
            }
          ]
        },
        {
          point: 'medical',
          title: '香港医疗服务',
          titleTips: 'Hong Kong Medical Services',
          list: [
            {
              img: require('@/assets/services_4.png'),
              title: '全港疫苗预约',
              tips: '帮助客户预约香港疫苗，实现早接种，早安心'
            },
            {
              img: require('@/assets/services_5.png'),
              title: '香港生育服务',
              tips: '帮助客户预约冻卵，IVF辅助生殖、港宝生产，帮助更多家庭轻松迎接下一代'
            },{
              img: require('@/assets/services_6.png'),
              title: '香港肿瘤治疗',
              tips: '帮助客户预约香港最顶尖的肿瘤治疗服务中心，提高客户生命质量'
            }
          ]
        },
        {
          point: 'invest',
          title: '香港投资理财',
          titleTips: 'Hong Kong Investment and Wealth Managemen',
          list: [
            {
              img: require('@/assets/services_7.png'),
              title: '香港购房',
              tips: '解决客户在港购房问题'
            },
            {
              img: require('@/assets/services_8.png'),
              title: '香港理财',
              tips: '对接香港家族办公室，帮助客户更好理财'
            },{
              img: require('@/assets/services_9.png'),
              title: '香港保险',
              tips: '对接AIA、保诚等知名险企，让客户在港买保险更加省心'
            }
          ]
        },
        {
          point: 'enterprise',
          title: '香港营商服务',
          titleTips: 'Hong Kong Business Services',
          list: [
            {
              img: require('@/assets/services_10.png'),
              title: '公司注册',
              tips: '解决客户在港注册公司'
            },
            {
              img: require('@/assets/services_11.png'),
              title: '财务审计',
              tips: '完成财务报表及审计报告，合法经营'
            },{
              img: require('@/assets/services_12.png'),
              title: '银行开户',
              tips: '对接香港各大银行，实现企业便捷开户'
            }
          ]
        },
      ],
      // swiperOption: {
      //   autoplay: false,
      //   loop: true,
      //   navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev',
      //     disabledClass: 'my-button-disabled',
      //   }  
      // }
    }
  },
  props: ['scroll'],
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll,true)
    this.$nextTick(()=>{
      let video = document.getElementById('bannerVideo')
      video.play()
    })
  },
  methods:{
    handleScroll(){
      // let scrollItems = document.querySelectorAll('.scroll-item')
      // for (let i = scrollItems.length - 1; i >= 0; i--) {
      //   let judge = this.scroll >= scrollItems[i].offsetTop - scrollItems[0].offsetTop
      //   if (judge) {
      //       this.$emit('scrollChangeTab', scrollItems[i].id, scrollItems[i].id );
      //       break
      //   }
      // }
    }
  },      
}

</script>

<style scoped lang="scss">
.banner {
  position: relative;
  width: 100%;
}
.banner-img {
  width: 100%;
}
.banner-mask {
  position: absolute;
  top: calc(50% - 200Px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 401Px;
  font-size: 100Px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #F2C84C;
  background: rgba(0,0,0,0.3);
  color: #F2C84C;
}
.video-js {
  background: unset;
}
.vjs-tech {
  height: unset;
}
.video {
  position: relative;
}
.video-mask {
  position: absolute;
  top: calc(50% - 200Px);
  width: 100%;
  height: 400Px;
  background: rgba(0,0,0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 100Px;
  font-weight: bold;
  color:rgba(242,200,76,1.000)
}
.advantages {
  margin-top: 76Px;
}
.common-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32Px;
  color: #333333;
  line-height: 42Px;
}
.common-tips {
  font-size: 14Px;
  font-weight: 300;
  color: #A6A6A6;
  line-height: 17Px;
  margin-top: 12Px;
}
.advantages-li {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 49Px;
}
.li{
  text-align: center;
  width: 270Px;
  margin: 0 17Px;
}
.li-title {
  font-size: 22Px;
  color: #454545;
  line-height: 29Px;
  padding: 31Px 0 12Px 0;
}
.li-tips {
  font-size: 12Px;
  color: #A6A6A6;
  line-height: 16Px;
}
// .li:last-child {
//   width: 192Px;
// }
.list-li {
  text-align: left;
  margin-top: 25Px;
  font-size: 14Px;
  color: #777777;
  line-height: 24Px;
}
.processing {
  margin-top: 88Px;
}
.processing-li {
  height: 574Px;
  margin-top: 48Px;
  display: flex;
  justify-content: center;
}
.processing-right {
  width: 572Px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 24Px;
}
.processing-right .processing-content {
  margin-bottom: 24Px;
  height: 274Px;
}
.processing-right .processing-content:nth-child(1) {
  margin-right: 24Px;
}
.processing-row {
  display: flex;
}
.processing-content {
  position: relative;
  &:hover {
    img, .processing-img-mask {
      transform: scale(1.02);
    }
    -webkit-box-shadow: 0 0 26Px #666;
    box-shadow: 0 0 26Px #666;
  }
  img {
    position: relative;
  }
  .processing-img-mask {
    position: absolute;
    top: 0;
    width: 274Px;
    height: 274Px;
    background: rgba(0,0,0, 0.2);
  }
}
.processing-one {
  width: 574Px;
  .processing-img-mask {
    position: absolute;
    top: 0;
    width: 574Px;
    height: 574Px;
  }
}
.processing-intr {
  position: absolute;
  bottom: 19Px;
  padding: 0 24Px;
}
.processing-title {
  font-size: 24Px;
  color: #FFFFFF;
  line-height: 31Px;
}
.processing-tips {
  font-size: 12Px;
  color: #FFFFFF;
  line-height: 16Px;
  padding-top: 10Px;
}
.processing-list {
  font-size: 14Px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  line-height: 19Px;
  margin: 12Px 0 0;
}
.services {
  margin-top: 39Px;
  display: flex;
  justify-content: center;
}
.services-li {
  margin: 0 12Px;
  position: relative;
  height: 272Px;
  &:hover {
    img, .services-img-mask {
      transform: scale(1.02);
      padding: 0;
      margin: 0;
    }
    -webkit-box-shadow: 0 2Px 12Px 0 #666;
    box-shadow:  0 2Px 12Px 0 #666;
  }
  img {
    position: relative;
  }
  .services-img-mask{
    position: absolute;
    top: 0;
    width: 374Px;
    height: 272Px;
    background: rgba(0,0,0, 0.2);
  }
}
.services-pc {
  display: block;
}
.services-h5 {
  display: none;
}
.services-title{
  font-size: 24Px;
  color: #FFFFFF;
  line-height: 31Px;
}
.services-tips {
  padding-top: 10Px;
  font-size: 12Px;
  color: #FFFFFF;
  line-height: 16Px;
}
.swiper-h5 {
  // position: relative;
  // padding: 0 80Px;
  // width: calc(100% - 160Px);
  // height: unset;
  // display: none;
}
.services-swiper {
  margin-top: 30Px;
}
.swiper-button-next, .swiper-button-prev {
  position: absolute;
  width: 44Px;
  height: 44Px;
}
.services-content {
  position: absolute;
  bottom: 20Px;
  left: 18Px;
  right: 18Px;
}
@include h5() {
  .banner-mask {
    top: calc(50% - 113px);
    width: 100%;
    height: 227px;
    font-size: 60px;
  }
  .advantages {
    margin-top: 51px;
  }
  .advantages-li {
    display: grid;
    grid-template-rows: 500px 500px;
    grid-template-columns: 50% 50%;
    margin: 49px 0 -20px 0;
    padding: 0 24px;
    .li {
      height: unset;
      width: unset;
      margin: 0;
      padding: 0 24px;
      img {
        width: 90px;
        height: 90px;
      }
      .li-title {
        font-size: 22px;
        color: #454545;
        line-height: 29px;
        padding: 31px 0 12px 0;
      }
      .list-li {
        margin-top: 25px;
        font-size: 14px;
      }
    }
    .li-tips {
      font-size: 12px;
      line-height: 16px;
    }
    .list-li {
      line-height: 30px;
    }
  }
  .common-title {
    font-size: 32px;
    line-height: 42px;
  }
  .common-tips {
    font-size: 18px;
    margin-top: 8px;
    line-height: 22px;
  }
  .processing {
    margin-top: 88px;
  }
  .processing-list {
    line-height: 34px;
    font-size: 20px;
    transform: scale(0.8);
    transform-origin: left;
  }
  .processing-tips {
    line-height: 24px;
    padding-top: 6px;
  }
  .processing-li {
    display: block;
    height: unset;
    margin-top: 26px;
  }
  .processing-right {
    width: calc(100% - 24px);
    margin-left: 0;
    padding: 0 12px;
    display: block;
  }
  .processing-content {
    width: calc(100% - 48px);
    padding: 0 12px;
    height: unset!important;
    margin: 0!important;
    img {
      width: 100%;
    }
    .processing-intr {
      padding: 0 10px;
      bottom: 19px;
    }
    .processing-img-mask {
      width: calc(100% - 24px);
      height: calc(100% - 6px);
    }
  }
  .processing-right .processing-content:nth-child(1) {
    padding-right: 12px!important;
  }
  .processing-title {
    font-size: 24px;
    line-height: 31px;
  }
  .processing-tips {
    font-size: 18px;
    transform: scale(0.8);
    transform-origin: left;
    line-height: 24px;
    opacity: 0.8;
  }
  .processing-one {
    width: calc(100% - 48px);
    margin: 0 auto 12px!important;
    img {
      width: 100%;
    }
    .processing-img-mask {
      width: calc(100% - 24px);
      height: calc(100% - 12px);
    }
  }
  .processing-row:first-child {
    margin-bottom: 12px;
  }
  .services-pc {
    display: none;
  }
  .services-h5 {
    display: block;
  }
  .swiper-h5 {
    display: block;
  }
  .services-li {
    margin: 0;
  }
  .services-swiper {
    margin-top: 30px;
  }
  .services-title{
    font-size: 24px;
    line-height: 31px;
  }
  .services-tips {
    padding-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>