<template>
    <div class="footer">
        <div class="footer-li" v-for="(itemK, indexK) in footerList" :key="indexK">
            <div class="footer-title" v-if="itemK.title">{{ itemK.title }}</div>
            <img class="footer-img" v-else :src="itemK.img" alt="">
            <div v-if="itemK.content" class="footer-content">{{ itemK.content }}</div>
            <div v-else class="footer-content  footer-content-list">
            <div class="footer-content-li" v-for="K in itemK.list" :key="K"> {{  K }} </div>
            </div>
            <div v-if="itemK.iconList" class="footer-icon">
            <img v-for="icon in itemK.iconList" :key="icon" :src="icon" alt="">
            </div>
        </div>
    </div>
</template>

  <script>
  export default {
    data() {
      return {
        footerList: [
          // {
          //   title: '关于我们',
          //   content: '主页'
          // },
          // {
          //   title: '联系我们',
          //   content: '关注',
          //   iconList: [
          //     require('@/assets/weixin.png'),
          //     require('@/assets/douyin-icon.png'),
          //   ]
          // },
          // {
          //   title: '我们的服务',
          //   list: ['香港身份', '教育升学','健康管理','财富管理']
          // },
          {
            title: '',
            img: require('@/assets/footer-logo.png'),
            list: ['博润前程于2014年创立，北京、香港两地办公，支持国家政府','推动两岸人才流动，两岸关系和谐发展，两岸文化融合。创始人为香港居民，毕业于华侨最高学府，长期定居香港，紧贴香港政策，精准服务新港人']
            // content: '博润前程于2014年创立，北京、香港两地办公，支持国家政府推动两岸人才流动，两岸关系和谐发展，两岸文化融合。创始人为香港居民，毕业于华侨最高学府，长期定居香港，紧贴香港政策，精准服务新港人'
          },
        ]
      }
    }
  }
  
  </script>
  
  <style scoped lang="scss">
  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 200Px;
    padding-top: 60Px;
    margin-top: 88Px;
    background: #F0F0F0;
  }
  // .footer-li:first-child {
  //   width: 199Px;
  // }
  // .footer-li:nth-child(2) {
  //   width: 215Px;
  // }
  // .footer-li:nth-child(3) {
  //   width: 302Px;
  // }
  .footer-li {
    width: 1170Px;
    margin: 0 auto;
  }
  .footer-li:last-child {
    // width: 416Px;
    .footer-content {
      margin-top: -82Px;
    }
  }
  .footer-title {
    font-size: 22Px;
    color: #333333;
    line-height: 32Px;
    margin-bottom: 18Px;
  }
  .footer-img {
    width: 194Px;
    margin: -82Px 0 13Px -50Px;
  }
  .footer-content {
    font-size: 14Px;
    font-family: MicrosoftYaHei;
    color: #B3B3B3;
    line-height: 24Px;
  }
  .footer-content-list {
    // width: 180Px;
  }
  .footer-content-li {
    padding-right: 30Px;
  }
  .footer-icon {
    display: flex;
    align-items: center;
    margin-top: 7Px;
  }
  .footer-icon img {
    padding-right: 18Px;
  }
  @include h5() {
    .footer {
        width: calc(100% - 58px);
        height: auto !important;;
        padding-bottom: 70px!important;
        padding: 0 0 0 58px;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 350px;
        padding-top: 58px;
        margin-top: 38px;
    }
    .footer-content {
        font-size: 20px;
        line-height: 24px;
    }
    .footer-li:first-child {
        min-width: 195px;
        width: 30.3%;
    }
    .footer-li:nth-child(2) {
        min-width: 214px;
        width: 30.3%;
    }
    .footer-li:nth-child(3) {
        min-width: 256px;
        width: 33.3%;
        .footer-content {
            width: 240px;
        }
    }
    .footer-li:last-child {
        min-width: 640px;
        width: calc(100% - 48px);
        margin-top: 30px;
        .footer-content {
          line-height: 34px;
        }
    }
    .footer-title {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 16px;
    }
    .footer-img {
        width: 194Px;
        height: unset;
        margin-bottom: 12px;
    }
    .footer-content-li {
        padding-right: 0px;
        font-size: 20px;
        line-height: 34px;
        // white-space: nowrap;
    }
  }
  </style>