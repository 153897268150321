<template>
    <div class="services-swiper">
        <swiper :class="`banner${itemIndex}`" :options="getOption(itemIndex)" ref="mySwiper">
        <swiper-slide :class="['services-li', {'about-services-li': type !== 'home'}]" v-for="(i,iIndex) in list" :key="iIndex">
            <img :src="i.img" alt=""/>
            <div v-if="type == 'home'" class="img-mask"></div>
            <div class="services-content" v-if="type == 'home'">
                <div class="services-title">{{ i.title }}</div>
                <div class="services-tips">{{ i.tips }}</div>
            </div>

            <div class="about-services-content" v-else>
              <div class="about-services-title">{{ i.title }}</div>
              <div class="about-services-tips">{{ i.tips }}</div>
              <div class="about-services-bar"></div>
              <div class="about-services-case">{{ i.content }}</div>
            </div>
        </swiper-slide>
        </swiper>
        <img :class="['swiper-button-next', `swp${itemIndex}`]" src="@/assets/swiper-right.png" alt="">
        <img :class="['swiper-button-prev', `swp${itemIndex}`]" src="@/assets/swiper-left.png" alt="">
    </div>
</template>

<script>
    import { swiper, swiperSlide } from "vue-awesome-swiper/src";
    export default {
        data() {
            return {
                swiperOption0: {
                    autoplay: false,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next.swp0',
                        prevEl: '.swiper-button-prev.swp0',
                    }  
                },
                swiperOption1: {
                    autoplay: false,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next.swp1',
                        prevEl: '.swiper-button-prev.swp1',
                    }  
                },
                swiperOption2: {
                    autoplay: false,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next.swp2',
                        prevEl: '.swiper-button-prev.swp2',
                    }  
                },
                swiperOption3: {
                    autoplay: false,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next.swp3',
                        prevEl: '.swiper-button-prev.swp3',
                    }  
                }
            }
        },
        props: {
            list: Array,
            type: String,
            itemIndex: {
                type: Number,
                default: ()=> 0
            }
        },
        components:{
            swiper,
            swiperSlide
        },
        methods: {
            getOption(index) {
                let item = ''
                switch(index) {
                    case 0:
                        this.item = this.swiperOption0
                        break;
                    case 1:
                        this.item = this.swiperOption1
                        break;
                    case 2:
                        this.item = this.swiperOption2
                        break;
                    case 3:
                        this.item = this.swiperOption3
                        break;
                }
                return this.item || this.item
            }
        }
    }
</script>
<style scoped lang="scss">
.services {
  margin-top: 39Px;
  display: flex;
  justify-content: center;
}
.services-li {
  margin: 0 12Px;
  position: relative;
}
.services-pc {
  display: block;
}
.swiper-h5 {
  position: relative;
  padding: 0 80Px;
  width: calc(100% - 160Px);
  height: unset;
  display: none;
}
.services-swiper {
  margin-top: 30Px;
}
.swiper-button-next, .swiper-button-prev {
  position: absolute;
  width: 44Px;
  height: 44Px;
}
.services-content {
  position: absolute;
  bottom: 20Px;
  left: 18Px;
  right: 18Px;
}
.services-title{
  font-size: 24Px;
  color: #FFFFFF;
  line-height: 31Px;
}
.services-tips {
  padding-top: 10Px;
  font-size: 12Px;
  color: #FFFFFF;
  line-height: 16Px;
}
.about-services-li {
    margin: 0 12Px;
    position: relative;
  }
  .about-services-content {
    position: absolute;
    top: 29Px;
    left: 22Px;
    right: 22Px;
  }
  .about-services-title{
    font-size: 16Px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 21Px;
  }
  .about-services-tips {
    padding: 5Px 0 8Px;
    font-size: 12Px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 16Px;
  }
  .about-services-bar {
    width: 86Px;
    height: 1Px;
    margin-bottom: 10Px;
    background: #fff;
  }
  .about-services-case {
    font-size: 14Px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 26Px;
  }

@include h5() {
  .banner-mask {
    top: calc(50% - 113px);
    width: 100%;
    height: 227px;
    font-size: 60px;
  }
  .advantages-li {
    display: grid;
    grid-template-rows: 500px 500px;
    grid-template-columns: 50% 50%;
    .li {
      width: unset;
      margin: 0;
      padding: 0 24px;
    }
    .list-li {
      line-height: 30px;
    }
  }
  .processing-list {
    line-height: 24px;
  }
  .processing-tips {
    line-height: 24px;
    padding-top: 6px;
  }
  .processing-li {
    display: block;
    height: unset;
  }
  .processing-right {
    width: calc(100% - 24px);
    margin-left: 0;
    padding: 0 12px;
    display: block;
  }
  .processing-content {
    width: calc(100% - 24px);
    height: unset!important;
    margin: 0!important;
    img {
      width: 100%;
    }
    .processing-intr {
      padding: 0 10px;
    }
  }
  .processing-right .processing-content:nth-child(1) {
    padding-right: 12px!important;
  }
  .processing-one {
    width: calc(100% - 25px);
    margin: auto!important;
    img {
      width: 100%;
    }
  }
  .services-pc {
    display: none;
  }
  .swiper-h5 {
    width: calc(100% - 160px);
    display: block;
  }
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    width: 44px;
    height: 44px;
    }
  .services-title {
      font-size: 24px;
      line-height: 31px;
  }
  .services-swiper {
    padding: 0 80px;
    margin-top: 30px;
  }
  .services-title{
    font-size: 24px;
    line-height: 31px;
  }
  .services-tips {
    font-size: 20px;
    transform: scale(0.8);
    transform-origin: left;
    line-height: 26px;
    padding-top: 8px;
    opacity: 0.8;
  }
  .services-content {
    left: 18px;
    right: 18px;
  }
  .about-services-title {
    font-size: 24px;
    line-height: 31px;
  }
  .about-services-tips {
    font-size: 18px;
    line-height: 24px;
    padding: 12px 0 16px;
  }
  .about-services-case {
    right: 24px;
    font-size: 20px;
    line-height: 34px;
  }
  .about-services-content {
    top: 60px;
    left: 35px;
    right: 35px;
  }
  .about-services-bar {
    width: 120px;
  }
  .about-services-li {
    width: 472px;
    height: 538px;
  }
  .services-li {
    margin: 0;
    position: relative;
    img {
        width: 100%;
    }
    .img-mask {
      position: absolute;
      top: 0;
      width: 100%;
      height: calc(100% - 4Px);
      background: rgba(0,0,0,0.2);
    }
  }
}
</style>